import {
    getOrderDetail
} from '@/utils/order.js';
import useClipboard from "vue-clipboard3";
export default {
    name: "AfterSuccess",
    data() {
        const isDetail = this.$route.path == '/afterDetail'
        return {
            isDetail,
            goodsInfo: [],
            orderInfo: '',
            userInfo: ''
        }
    },
    created() {
        this.orderInfo = JSON.parse(sessionStorage.getItem('orderInfo'));
        this.userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
        this.getOrderDetailFunc();
    },
    methods: {
        // 获取订单详情
        getOrderDetailFunc() {
            let data = {
                memberId: this.$cookies.get('memberId'),
                id: this.orderInfo.id
            }
            getOrderDetail(data).then(res => {
                this.orderInfo = res.data;
                this.goodsInfo = res.data.orderGoodItemList;
                sessionStorage.setItem('orderInfo', JSON.stringify(res.data));
            })
        },
        // 复制微信号
        async copyFun(data) {
            try {
                const { toClipboard } = useClipboard()
                await toClipboard(data)
                this.$toast.success("已复制微信号")
            } catch (e) {
                console.error(e)
            }
        },
    }
}