<template>
  <div class="after-success">
    <div v-if="!isDetail" class="t-box flex">
      <img src="@/assets/img/order/after/buhuo.png" alt="" />
      <div class="c-hold">
        <p>提交成功</p>
        <p>灵龙客服即将处理您的申请，预计24小时内会给予反馈</p>
      </div>
      <p class="copy" @click="copyFun('18017657468')">复制企业微信号</p>
    </div>
    <div :class="['goods-box flex', { 'goods-detail': !isDetail }]" v-for="item in goodsInfo" :key="item.id">
      <div class="goods-box-img" :style="{background: 'url('+item.goodsImage+')no-repeat center center',backgroundSize: 'cover'}"></div>
      <div class="right">
        <p class="title">{{item.goodName}}</p>
        <p class="attr">规格</p>
        <div class="goot flex align-center flex-between">
          <p>¥{{parseFloat(item.promotionPrice/100).toFixed(2)}}</p>
          <p>×{{item.goodNum}}</p>
        </div>
      </div>
    </div>
    <div v-if="!isDetail" class="goods-status flex align-center flex-between">
      <p>收货状态</p>
      <p v-if="orderInfo.refundType == 1">已收到货</p>
      <p v-if="orderInfo.refundType == 2">未收到货</p>
      <p v-if="orderInfo.refundType == 3">换货</p>
    </div>
    <div class="main-box">
      <p class="title">退款信息</p>
      <div class="m-li flex">
        <p>退款原因</p>
        <p>{{orderInfo.refundReason}}</p>
      </div>
      <div class="m-li flex">
        <p>退款金额</p>
        <p class="m-red">¥ {{parseFloat(orderInfo.refundAmount/100).toFixed(2)}}</p>
      </div>
      <div class="m-li flex">
        <p>联系方式</p>
        <p>{{userInfo.user_tele}}</p>
      </div>
      <div class="m-li flex flex-between">
        <p>退款说明</p>
        <p class="reason">{{orderInfo.refundDetail}}</p>
      </div>
      <div class="m-li flex" v-if="orderInfo.refundImg">
        <p>凭证</p>
        <div class="pic">
          <img v-for="item in orderInfo.refundImg.split(',')" :key="item.id" :src="item" alt="" class="m-l" />
          <!-- <img src="@/assets/img/order/after/ping.jpg" alt="" /> -->
        </div>
      </div>
    </div>
    <div v-if="!isDetail" class="bottom-box">
      <p class="title">售后信息</p>
      <div class="m-li flex">
        <p>售后编号</p>
        <p>{{orderInfo.orderNo}}</p>
        <img src="@/assets/img/order/after/copy.png" alt=""  @click="copyFun(orderInfo.orderNo)"/>
      </div>
      <div class="m-li flex">
        <p>申请时间</p>
        <p>{{orderInfo.userRefundTime}}</p>
      </div>
    </div>
  </div>
</template>
<script src="./index.js"></script>
<style lang="scss" scoped>
@import "./index.scss";
</style>